import { useQuery } from "@apollo/client";
import Select from "react-select";
import { GET_ALL_USERS } from "_queries/Admin";
import { fullName, transformDataIntoValueLabel } from "_utils";
import { userStatus } from "_constants";

export const UsersDropdown = (props) => {
  const { onUserChange } = props;
  const orderBy = props.orderByKey
    ? { field: props.orderByKey, value: props.orderByValue }
    : { field: "createdAt", value: "desc" };

  const { data } = useQuery(GET_ALL_USERS, {
    variables: {
      filters: {
        fields: [
          {
            field: "status",
            value: userStatus.ACTIVE,
            type: "normal",
          },
        ],
      },
      pagination: { take: 500, skip: 0 },
      orderBy: orderBy,
    },
  });
  const users = data?.users.users.map((user) => {
    return {
      ...user,
      title: `${fullName(user.firstName, user.lastName)} - ${user.referenceId}`,
    };
  });

  const handleChange = (inputValue) => {
    const val = inputValue ? inputValue.value : "all";
    onUserChange(val);
  };
  return (
    <Select
      placeholder={`Select User`}
      isClearable={true}
      defaultOptions={false}
      options={transformDataIntoValueLabel(users, "title", "_id")}
      onChange={handleChange}
    />
  );
};
