import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query (
    $filters: FiltersExpression!
    $pagination: PaginationInput!
    $orderBy: OrderByInput!
  ) {
    users(filters: $filters, pagination: $pagination, orderBy: $orderBy) {
      count
      users {
        _id
        firstName
        lastName
        email
        phone
        referenceId
        status
        website
        isEmailConfirmed
        createdAt
      }
    }
  }
`;
